<template>
  <div class="downloadList">
    <div class="main-box">
      <div class="breadcrumb">
        <router-link :to="{name:'downloadCenter'}">下载专区 / </router-link>
        <span>{{categoryName}}</span>
      </div>
      <div class="content">
        <div class="types">
          <div @click="typeCg(item.id)" v-for="(item) in types" :key="item.id" :class="{active:item.id===type}">{{item.categoryName}}</div>
        </div>
        <div class="list">
          <div v-for="(item,index) in texts" :key="index">
            <div class="name" @click="showInfo==index?showInfo=-1:showInfo=index">
              {{item.contentTitle}}
              <svg-icon class="icon" :class="{rotate:showInfo==index}" icon-class="pulldown" />
            </div>
            <el-collapse-transition>
              <div v-show="index==showInfo">
                <div ref="answer" class="info">
                  <div><span v-if="item.version">版本：v{{item.version}}</span><span v-if="item.fileType">类型：{{fileTypes[item.fileType]}}</span><span
                      v-if="item.fileSize">大小：{{item.fileSize | fileSize}}</span><span v-if="item.pubdate">发布时间：{{item.pubdate}}</span>
                  </div>
                  <div v-if="item.supportSystem"><span>支持系统：{{item.supportSystem}}</span></div>
                </div>
                <el-button :loading="item.percentage>0" @click.stop="getDownloadcontentFile(index)" type="primary" size="small">{{item.percentage>0?'下载中':'下载'}}</el-button>
                <el-button @click.stop="copyUrl(index)" type="primary" size="small">分享</el-button>
                <el-progress v-show="item.percentage>0" :percentage="item.percentage" color="$primaryColor"></el-progress>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type: 1,
      types: [],
      showInfo: -1,
      texts: [],
      pageIndex: 1,
      pageSize: 10,
      fileTypes: {
        '1': '驱动程序',
        '2': '客户端软件',
        '3': '文档',
        '4': '文件'
      }
    }
  },
  created () {
    this.getContentcategory()
  },
  mounted () {
    this.texts = []
  },
  methods: {
    getContentcategory () {
      this.$api.getContentcategory({ categoryType: 1 }).then(res => {
        this.types = res.data
        let type = this.$route.query.type
        this.type = type || type === 0 ? type : res.data[0].id
        this.getDownloadcontentList(type)
      })
    },
    getDownloadcontentList (type) {
      this.$api
        .getDownloadcontentList({
          categoryId: this.type,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        })
        .then(res => {
          if (type === this.type) {
            this.texts.push(...res.data.list)
            if (res.data.hasNext) {
              this.pageIndex++
              this.getDownloadcontentList(type)
            }
          }
        })
    },
    copyUrl (index) {
      let copy = url => {
        this.$copyText(url).then(
          () => {
            this.$message({
              message: '下载链接已成功复制，前往粘贴进行分享',
              type: 'success'
            })
          },
          () => {
            this.$message.error('复制失败，请重试')
          }
        )
      }
      if (this.texts[index].fileType === '4') {
        copy(this.texts[index].url)
      } else {
        this.$api
          .getDownloadcontentFile({
            fileId: this.texts[index].fileId,
            version: this.texts[index].version
          })
          .then(res => {
            copy(res.data.url)
          })
      }
    },
    getDownloadcontentFile (index) {
      if (this.texts[index].fileType === '4') {
        window.open(this.texts[index].url, '_blank')
      } else {
        let setPercentage = (percentage, id) => {
          let file = this.texts.find(item => item.id === id)
          if (file) {
            this.$set(file, 'percentage', percentage)
          }
        }
        let getBlob = (res, id) => {
          let xhr = new XMLHttpRequest()
          xhr.open('GET', res.data.url, true)
          xhr.responseType = 'blob'
          xhr.onload = () => {
            if (xhr.status === 200) {
              this.$util.downloadFile(xhr.response, res.data.filename)
              setPercentage(0, id)
            } else {
              setPercentage(0, id)
              this.$message.error('获取文件失败')
            }
          }
          xhr.onerror = () => {
            setPercentage(0, id)
            this.$message.error('获取文件失败')
          }
          xhr.onprogress = evt => {
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total
              let percentage = Math.floor(percentComplete * 10000) / 100
              setPercentage(percentage, id)
            }
          }
          xhr.send()
        }
        setPercentage(0.01, this.texts[index].id)

        this.$api
          .getDownloadcontentFile({
            fileId: this.texts[index].fileId,
            version: this.texts[index].version
          })
          .then(res => {
            getBlob(res, this.texts[index].id)
          })
          .catch(() => {
            setPercentage(0, this.texts[index].id)
          })
      }
    },
    typeCg (type) {
      if (type !== this.type) {
        this.showInfo = -1
        this.pageIndex = 1
        this.texts = []
        this.type = type
        this.$router.push({
          query: { type }
        })
        this.getDownloadcontentList(type)
      }
    }
  },
  computed: {
    categoryName () {
      let name = ''
      for (let type of this.types) {
        if (type.id === this.type) {
          name = type.categoryName
        }
      }
      return name
    }
  },
  filters: {
    fileSize (val) {
      val = Number(val)
      if (val > 1024 * 1024 * 1024) {
        return (val / 1024 / 1024 / 1024).toFixed(2) + 'GB'
      } else if (val > 1024 * 1024) {
        return (val / 1024 / 1024).toFixed(2) + 'MB'
      } else if (val > 1024) {
        return (val / 1024).toFixed(2) + 'KB'
      } else {
        return val + 'B'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.downloadList {
  padding-top: 60px;
  background: #f9fbff;
  padding-bottom: 160px;
  .breadcrumb {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 19px;
    margin-top: 32px;
    margin-bottom: 24px;
    a {
      color: #a2a9b4;
    }
    span {
      color: #303b50;
      font-weight: bold;
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    .types {
      width: 258px;
      background: #ffffff;
      border-radius: 2px;
      padding: 32px 0;
      font-size: 14px;
      color: #303b50;
      margin-right: 16px;
      flex-shrink: 0;
      & > div {
        padding-left: 14px;
        margin-bottom: 16px;
        line-height: 35px;
        border-left: 2px transparent solid;
        cursor: pointer;
        &:last-child {
          margin: 0;
        }
        &.active {
          color: $primaryColor;
          border-left: 2px $primaryColor solid;
        }
        &:hover {
          color: $primaryColor;
        }
      }
    }
    .list {
      background: #ffffff;
      width: 100%;

      & > div {
        padding: 40px 50px;
        border-bottom: 1px dashed #d9d9d9;
        &:last-child {
          border: none;
        }
        .name {
          font-size: 16px;
          font-weight: bold;
          color: #303b50;
          line-height: 21px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover {
            color: $primaryColor;
            .icon {
              color: $primaryColor;
            }
          }
          .icon {
            color: #d4d4da;
            font-size: 14px;
            font-weight: normal;
            transition: all 0.2s;
            &.rotate {
              transform: rotate(180deg);
            }
          }
        }
        .info {
          font-size: 14px;
          color: #303b50;
          line-height: 42px;
          padding: 20px 0;
          span {
            margin-right: 44px;
          }
        }
        .el-button {
          margin-top: 8px;
          min-width: 66px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
